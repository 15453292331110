<template>
  <div>
    <div class="live_box dis_flex_start">
      <div @click="userLogin()" class="livingBox">
        <template v-if="uservideoConfig != '' && accessKeyId && accessKeySecret">
          <div v-if="!pass" class="maskBox">
            <img :src="maskImg" alt="" />
            <div class="mask">
              <div v-if="detail.state == 2 || detail.state == 1">
                <img src="@/assets/img/partner/zhibo.png" alt="" style="width: 84px; height: 84px; margin: 0 auto 10px"
                  @click="pass = true" />
                <div class="btn" :class="detail.state == 1 ? 'zhibo' : ''" @click="pass = true">
                  {{ stateb }}
                </div>
              </div>
              <div v-else style="text-align: center">
                <div class="countDown" style="width: 100%; text-align: center">
                  直播倒计时
                </div>
                <cutDown @isOk="isOk" :start-time="detail.zbBeginTime" />
              </div>
            </div>
          </div>
          <jxyplayer v-else :url="uservideoConfig" :access-key-id="accessKeyId" :access-key-secret="accessKeySecret"
            :autoplay="true" :options="option" />
        </template>
        <template v-else>
          <div class="maskBox">
            <img :src="maskImg" alt="" />
            <div class="mask">
              <div class="btn">回放生成中</div>
            </div>
          </div>
        </template>
      </div>
      <div class="open_list">
        <div v-for="(items, indexs) in openClasslist" :key="indexs" class="open_item"
          :class="active == items.id ? 'active' : ''" @click="getDetail(items)">
          <div class="imgBox">
            <img src="@/assets/img/partner/timeline.png" alt="" />
          </div>
          <div class="title">
            <div class="dis_flex_start">
              <div class="tips">{{ items.stateb }}</div>
              <div class="time">{{ items.zbBeginTime }}</div>
            </div>
            <div class="til">{{ items.name }}</div>
          </div>
          <div class="tea">
            <div class="tea_img">
              <img :src="items.lecturerUrl" alt="" />
            </div>
            <div class="name">
              {{ items.lecturerName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="searchBox">
      <kind-choose-view :show-school="true" :show-kc="false" :show-zy="false" :show-level="false" :type="null"
        :if-img="true" :entrants-type="3" @isOk="isOk" />
    </div>
    <div class="bigBox">
      <!-- 列表 -->
      <div class="courseList">

        <div v-for="(item, index) in list" :key="index" class="courstItem" :class="item.state == 1 ? 'liveIng' : ''"
          @click="goDetail(item)">
          <img class="itemImg" :src="item.pcPictureUrl" />
          <div class="contentItem">
            <div class="titleItem twoEllipsis">{{ item.name }}</div>
            <div class="timeItem">
              <span>直播时间：{{ item.zbBeginTime }}</span>
            </div>
            <div class="line"></div>
            <div class="teacherItem">
              <div class="dis_flex_start" style="align-items: center">
                <div class="imgBox">
                  <img :src="item.lecturerUrl" alt="" />
                </div>
                <div class="itemBox">讲师：{{ item.lecturerName }}</div>
              </div>
              <el-button class="but">
                {{ item.stateb }}
              </el-button>
            </div>
          </div>
        </div>
        <div style="margin: 0 auto;" v-if="!list || list.length == 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination colleges-pagination" :current-page="page.pageNum" :page-sizes="[12, 24, 36, 48]"
        :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getSurfaceList } from "@/api/home";
import KindChooseView from "@/components/kindChooseViewsPartner.vue";
import jxyplayer from "@/components/jxyPlay.vue";
import { getAccessKey } from "@/api/home";
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import cutDown from "@/components/cutdownPublic.vue";
import { Know } from "@/api/know.js";
const know = new Know();
export default {
  components: {
    KindChooseView,
    jxyplayer,
    cutDown,
  },
  data() {
    return {
      page: {
        pageSize: 12,
        pageNum: 1,
        total: 0,
      },
      list: [], // 课程列表
      openClasslist: [], // 课程列表
      currentItem: null, // 选中的公开课
      searchForm: {},
      uservideoConfig: "",
      maskImg: "",
      accessKeyId: "",
      active: "",
      detail: {},
      pass: "",
      stateb: "",
      accessKeySecret: "",
      userInfo: null,
      option: {
        width: "578px",
        height: "326px",
        url: this.uservideoConfig,
        type: "HLS",
      },
      liveTodayList: [], // 今日直播列表
      zbStartTime: null,
    };
  },
  computed: {
    empty() {
      console.log(this.page.total % 12);
      if (this.page.pageNum !== 1) {
        if (Math.trunc(this.page.total / 12) + 1 === this.page.pageNum) {
          return 4 - (this.page.total % 12);
        } else {
          return 0;
        }
      } else {
        return Math.abs(4 - (this.page.total % 12));
      }
    },
  },
  created() {
    const date = new Date();
    this.zbStartTime = date.toLocaleDateString();
    // this.liveToday();
    this.userInfo = getInfo();
    this.getAccessKey();
    this.getList();
    this.getOpenClass();
  },
  methods: {
    isOk() {
      this.getList();

    },
    mouseover() {
      const cover = document.getElementById("cover");
      cover.style.display = "flex";
    },
    mouseout() {
      const cover = document.getElementById("cover");
      cover.style.display = "none";
    },
    isOk(val) {
      this.searchForm.zyId = val.zyId;
      this.searchForm.zState = val.statenName;
      this.searchForm.kindId = val.kind;
      this.searchForm.schoolId = val.school;
      this.getList();
    },
    getOpenClass() {
      const data = {
        pageNum: 1,
        pageSize: 4,
        kindId: undefined,
        zStage: undefined,
        zyId: undefined,
        /* state: 3, */
        isShow: 1,
      };
      know.getNewCollegesPublicClassList(data).then((res) => {
        if (res.rows && res.rows.length > 0) {
          this.getDetail(res.rows[0]);
        }
        this.openClasslist = res.rows.filter((item, index) => {
          const month = new Date(item.zbBeginTime).getMonth() + 1;
          const date = new Date(item.zbBeginTime).getDate();
          const hour = new Date(item.zbBeginTime).getHours();
          const minute = new Date(item.zbBeginTime).getUTCMinutes();
          const nowMonth = new Date().getMonth() + 1;
          const nowDate = new Date().getDate();
          if (item.state == 2) {
            item.stateb = "查看回放";
          } else if (item.state == 1) {
            item.stateb = "进入直播";
          } else {
            item.stateb = "即将开始";
          }
          if (nowMonth == month && nowDate == date) {
            // item.zbBeginTimeNew = `今天` //
            item.zbBeginTimeNew = `${month <= 9 ? "0" + month : month}/${date <= 9 ? "0" + date : date
              }`; //
            item.zbBeginTimeNewCh = `${month <= 9 ? "0" + month : month}月${date <= 9 ? "0" + date : date
              }日`;
            item.zbBeginTimeDay = ` ${hour <= 9 ? "0" + hour : hour}:${minute <= 9 ? "0" + minute : minute
              }`;
          } else {
            item.zbBeginTimeNew = `${month <= 9 ? "0" + month : month}/${date <= 9 ? "0" + date : date
              }`; //
            item.zbBeginTimeNewCh = `${month <= 9 ? "0" + month : month}月${date <= 9 ? "0" + date : date
              }日`;
            item.zbBeginTimeDay = `${hour <= 9 ? "0" + hour : hour}:${minute <= 9 ? "0" + minute : minute
              }`;
          }
          return index < 4;
        });
        this.stateb = this.openClasslist[0].stateb

      });
    },
    /* 公开课列表 */
    getList() {
      const data = {
        ...this.page,
        ...this.searchForm,
      };
      delete data.total;
      know.getNewPublicClassList(data).then(async (res) => {

        res.rows.filter((item, index) => {
          const month = new Date(item.zbBeginTime).getMonth() + 1;
          const date = new Date(item.zbBeginTime).getDate();
          const Hour = new Date(item.zbBeginTime).getHours();
          const Minutes = new Date(item.zbBeginTime).getMinutes();
          const nowMonth = new Date().getMonth() + 1;
          const nowDate = new Date().getDate();
          const nowHour = new Date().getHours();
          const nowMinutes = new Date().getMinutes();
          if (item.state == 2) {
            item.stateb = "查看回放";
          } else if (item.state == 1) {
            item.stateb = "进入直播";
          } else {
            item.stateb = "未开始";
          }
        });


        this.list = res.rows;
        this.imQunId = res.rows[0].imQunId;
        this.page.total = res.total;
      });
    },
    /* 今日直播列表 */
    liveToday() {
      const data = {
        pageSize: 999,
        pageNum: 1,
        zbStartTime: this.zbStartTime,
        zbEndTime: this.zbStartTime,
      };
      know.getNewPublicClassList(data).then((res) => {
        if (res.rows.length > 0) {
          this.currentItem = res.rows[0];
        }
        this.liveTodayList = res.rows;
        // this.active = this.liveTodayList[0].id
        this.liveTodayList.map((item, index) => {
          if (item.state == 1) {
            this.liveTodayList.unshift(this.liveTodayList.splice(index, 1)[0]);
          }
        });
      });
    },
    async getAccessKey() {
      const res = await getAccessKey();
      if (res.code == 0) {
        this.accessKey = res.data;
        this.accessKeyId = res.data.accessKeyId;
        this.accessKeySecret = res.data.accessKeySecret;
      }
    },
    getDetail(item) {
      console.log(item, 'itemitem');
      this.active = item.id;
      this.stateb = item.stateb;
      this.pass = false;
      const data = {
        id: item.id,
      };
      know
        .getPublicityClassDetail(data)
        .then((res) => {
          this.imQunId = res.msg.imQunId;
          /* this.imQunId = res.msg.imQunId; */
          res.msg.endTime = new Date(res.msg.zbFinishTime).getTime();
          // this.courseDetail = res.msg;
          if (res.msg.state == 1) {
            this.$nextTick(() => {
              this.uservideoConfig = res.msg.wzUrl;
            });
          } else {
            this.uservideoConfig = res.msg.playbackUrl;
          }
          this.maskImg = res.msg.coverUrl;
          this.detail = res.msg;
        })
        .then(() => {
          if (!this.imQunId) return;
        });
    },
    // async crePlayer(player) {
    //   const that = this;
    //   that.player = player;
    //   player.on("timeupdate", function () {
    //     // 这个方法250毫秒触发一次
    //     if (that.player.currentTime > that.finishTime) {
    //       if (that.dottingId) {
    //         that.player.pause();
    //       }
    //       that.dottingId = null;
    //     }
    //   });
    //   player.on("playing", function () {
    //     // that.player.currentTime = that.history
    //     that.addLearn();
    //     if (!that.timer) {
    //       that.getTime();
    //     }
    //   });
    //   player.on("ended", function () {
    //     // player.fullscreenService.cancelFullScreen()
    //     that.clear();
    //   });
    //   player.on("pause", function () {
    //     that.stopLearn();
    //     that.clear();
    //   });
    //   player.on("error", function () {
    //     that.clear();
    //   });
    //   player.on("ready", async function () {
    //     setTimeout(() => {
    //       player.play();
    //     }, 1000);
    //   });
    // },
    goDetail(item) {
      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: "error",
            message: "回放生成中",
          });
        } else {
          this.addRecord(item);
          const routeUrl = this.$router.resolve({
            path: "/publicClass/publicClassDetail",
            query: {
              imQunId: item.imQunId,
              id: item.id,
            },
          });
          window.open(routeUrl.href, "_blank");
        }
      } else {
        Bus.$emit("toLoginPage", true);
      }
      /* if (item.state == 2 && !item.playbackUrl) {
        this.$message({
          type: "error",
          message: "回放生成中",
        });
      } else {
        this.addRecord(item);
        let routeUrl = this.$router.resolve({
          path: "/publicClass/publicClassDetail",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } */
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null,
      };
      know.addPublicityClassStudentRecord(data).then((res) => { });
    },
    /* 今日直播课程选择 */
    chooseCourse(item) {
      this.currentItem = JSON.parse(JSON.stringify(item));
    },

    /* 搜索 */
    selectPageList() {
      this.page = {
        pageSize: 12,
        pageNum: 1,
        total: 0,
      };
      this.getList();
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNum = 1;
      this.getList();
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    userLogin() {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(false);
        this.pass = false
        return;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.live_box {
  width: 1200px;
  height: 358px;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  padding: 20px 20px 0px 20px;
  opacity: 1;

  .livingBox {
    border-radius: 10px;
    overflow: hidden;
    width: 578px;
    height: 326px;

    .maskBox {
      position: relative;

      img {
        width: 100%;
      }

      .mask {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          width: 130px;
          height: 40px;
          border-radius: 20px 20px 20px 20px;
          opacity: 1;
          border: 1px solid #ffffff;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }

        .zhibo {
          width: 130px;
          height: 40px;
          background: linear-gradient(180deg, #ffab5b 0%, #ff761b 100%);
          border-radius: 20px 20px 20px 20px;
          opacity: 1;
          border: none;
        }
      }
    }
  }

  .countDown {
    width: 70px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #dddddd;
    margin-bottom: 14px;
  }

  .open_list {
    margin-left: 20px;

    .open_item {
      cursor: pointer;
      display: flex;
      align-items: start;
      justify-content: start;
      width: 560px;
      height: 74px;
      padding: 10px 0 0 20px;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-bottom: 10px;

      .imgBox {
        width: 18.5px;

        img {
          width: 100%;
        }
      }

      .title {
        flex: 1;
        margin-left: 20px;

        .tips {
          color: #666;
          background: #f1f1f1;
          border-radius: 10px;
          opacity: 1;
          padding: 2px 6px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .time {
          height: 22px;
          margin-left: 10px;
          line-height: 22px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }

      .tea {
        width: 60px;
        margin-right: 7px;

        .tea_img {
          margin: 0 auto;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .name {
          width: 100%;
          text-align: center;
          margin-top: 4px;
          height: 17px;
          line-height: 17px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }

      .til {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-top: 10px;
      }
    }

    .active {
      background: #f5f6f7;

      .tips {
        color: #f8343e !important;
        background: #fce4e4 !important;
      }

      .til {
        color: #006de7;
      }
    }
  }
}

.bigBox {
  width: calc(1200px);
  height: auto;
  margin: 0px calc(50% - 600px);

  .courseList {
    display: flex;
    flex-wrap: wrap;

    /* 正在直播 */
    .liveIng {
      .timeItem {
        color: #4a6af0 !important;

        .iconfont {
          color: #4a6af0 !important;
        }
      }
    }

    .but {
      background: #ffffff !important;
      color: #4a6af0 !important;
    }

    .courstItem {
      cursor: pointer;
      width: 280px;
      height: 320px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      overflow: hidden;
      margin: 20px 26px 0px 0px;

      // display: flex;
      // padding: 24px;
      &:nth-child(4n) {
        margin-right: 0;
      }

      .itemImg {
        width: 280px;
        height: 156px;
        background: #ffffff;
        opacity: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .contentItem {
        width: 100%;
        padding: 11px;

        .timeItem {
          margin-top: 8px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #4B4F54;
          line-height: 12px;

          .iconfont {
            font-size: 12px;
            color: #4B4F54;
            margin-right: 4px;
          }
        }

        .titleItem {
          width: 259px;
          height: 44px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
        }

        .teacherItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;

          .imgBox {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .itemBox {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }

          .but {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 36px;
            background: #ffffff;
            border-radius: 18px 18px 18px 18px;
            opacity: 1;
            border: 1px solid #006de7;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #006de7;

            &:hover {
              background-color: #006de7 !important;
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }

  .contentWrap_item {
    width: 280px;
    height: 320px;
    background: #ffffff;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 27px;
    margin-top: 20px;

    .item_item {
      width: 63px;
      height: 54px;
      margin-top: -20px;

      .item_img {
        width: 100%;
        height: 100%;
      }
    }

    .item_name {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #e4e9ed;
      margin-top: 7px;
    }
  }

  .contentWrap_item:nth-child(4n) {
    margin-right: 0;
  }

  .tag {
    height: 20px;
    border-radius: 3px;
    padding: 0 8px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
  }

  .tagBack {
    background: linear-gradient(136deg, #727a8a 0%, #494e59 100%);
  }

  .tagLive {
    background: linear-gradient(138deg, #ff9a51 0%, #ff5e51 100%);
  }

  .tagNot {
    background: linear-gradient(136deg, #727a8a 0%, #555555 100%);
  }
}

.tui-img {
  margin: 0;
  width: 100%;
}

.line {

  height: 0px;
  opacity: 1;
  border-bottom: 1px solid #EEEEEE;
  margin-top: 20px;
}
</style>
